import React, { FunctionComponent } from "react"
import Answer from "./Answer"
import styles from "./Faq.module.css"
import Highlight from "./Highlight"
import { Link } from "gatsby"

const Faq: FunctionComponent = () => {
  return (
    <ul className={styles.root} itemScope itemType="https://schema.org/FAQPage">
      <li>
        <h3 className="pt-20 mb-8">Cost & subscription</h3>
        <ul>
          <Answer title="How much does Breezy cost?">
            <p>Breezy is a subscription product which you can pay for monthly or quarterly. See <Link
              to={"/pricing"}>here</Link> for our pricing plans.</p>
          </Answer>
          <Answer title="Can I cancel my subscription?">
            <p>Yes, you can easily cancel your subscription at any time via your Brand Account or by emailing us at <a
              href="mailto:team@breezy.io">team@breezy.io</a>.</p>
            <p>Your cancellation will take effect at the end of your
              current billing period which corresponds to the last day of your Subscription Cycle. You’re responsible
              for all charges associated with your Subscription up until the end of the current subscription.</p>
              <p>Get in touch to discuss how a subscription to Breezy can suit your needs.</p>
          </Answer>
          <Answer title="What features are included with free trial?">
            <p>A free trial for Breezy will give you 50 Breezy Credits to use on your first partner discovery.</p>
            <p>With a free trial, you’ll have access to Breezy’s keyword-based search, partner relevancy ranking,
              competitor partner analysis, advanced filters, the ability to save Leads to your ‘favourites’ and
              more.</p>
            <p>Essentially, you’ll be able to preview everything that you need to make partner discovery easy.
              If you like what you see, all you have to do is top up your Breezy Credits to continue
              running discoveries using our tailored partner discovery engine.</p>
          </Answer>
        </ul>
      </li>
      <li>
        <h3 className="pt-20 mb-8">Why & who</h3>
        <ul>
          <Answer title="Why should I register with Breezy?">
            <p>Finding partners is hard and time-consuming. In fact, the average affiliate or partnership manager spends
              35% of their time sourcing new partners!</p>
            <p>Up until now, there haven’t been any solutions that fully solved this problem.</p>
            <p>Out of the businesses that already use partnerships as a growth channel, 100,000 aren’t on networks.
              These
              businesses are handling partner discovery and outreach from scratch! </p>
            <p>Meanwhile, 550,000 businesses are on networks. But since networks only cater for a small portion of
              opportunities, these businesses also have to look for other relevant partners beyond this or risk losing
              out.</p>
            <p className={styles.highlight}>
              <Highlight
                text="All in all, a solution that makes it quick and easy to find highly relevant partners has been a long time
              coming."/>
            </p>
          </Answer>
          <Answer title="Who is Breezy for?">
            <p>If you’re part of a company that uses partnerships as a growth funnel (or would like to) then Breezy is
              for
              you. Our customers are:</p>
            <ol>
              <li>Businesses that already engage in partnerships… and are tired of the time and effort it takes to find
                partners.
              </li>
              <li>Businesses that don’t yet use partnerships… but would if it was quicker and easier.</li>
            </ol>
            <p>Our primary users include partnership managers, marketing managers, growth managers, CEOs and founders of
              small companies who are looking to self-grow their businesses.</p>
          </Answer>
        </ul>
      </li>
      <li>
        <h3 className="pt-20 mb-8">Strategic partnerships</h3>
        <ul>
          <Answer title="What does a strategic partnership mean?">
            <p>A strategic partnership is when two or more brands collaborate to bring benefits to both. This might be
              by
              teaming up on a strategic marketing campaign, creating a new product or anything in between. </p>
            <p>Read our article on the <Link to={"/blog/the-14-types-of-strategic-partnerships"}>types of strategic
              partnership</Link> to
              see the huge variety of strategic partnerships out there.</p>
          </Answer>
          <Answer title="How do you develop strategic partnerships?">
            <p>Developing a successful strategic partnership all starts at the discovery phase. For help on partner
              discovery,
              check out these easy ways to <Link to={"/blog/find-strategic-partnerships"}>find strategic
                partners.</Link> Or,
              simply <Link to={"/signup"}>Book a demo</Link> of the Breezy platform to get started!</p>
            <p>However, it doesn’t end there. Once you’ve found the right partner, you’ll need to set realistic goals
              and
              expectations during the negotiation phase and then continue to nurture your relationship long after.
              Our piece on <Link to={"/blog/develop-successful-strategic-partnerships"}>why partnerships
                fail</Link> should
              set you off on the right track.</p>
          </Answer>
          <Answer title="How do I know what kind of strategic partnership is right for me?">
            <p>Every brand is different, so no two strategic partnerships are quite the same.</p>
            <p>With that in mind, the first step is always to ask yourself why you want a strategic partnership. Once
              you’ve come up with some clear objectives, you’ll need to consider whether the brands and partnership
              types
              you’re considering can help you to achieve those goals.</p>
            <p>Our guide to <Link to={"/blog/strategic-partnership-guide"}>finding the right strategic partnership for
              your brand</Link> is a great place to start.
            </p>
          </Answer>
        </ul>
      </li>
      <li>
        <h3 className="pt-20 mb-8">Affiliate marketing</h3>
        <ul>
          <Answer title="What is affiliate marketing and how does it work?">
            <p>Affiliate marketing is a type of partnership marketing where a brand teams up with an affiliate marketer
              (also known as a publisher or affiliate). The affiliate receives performance-based commissions for
              promoting
              the brand’s products to their followers.</p>
            <p>Affiliates can come in all different shapes and sizes, from complementary brands to newspapers or
              influencers.</p>
            <p>Read our <Link to={"/blog/beginners-guide-affiliate-marketing"}>beginner’s guide to affiliate
              marketing</Link> to find out more.</p>
          </Answer>
          <Answer title="Is Breezy an affiliate network?">
            <p>Nope, we’re not an affiliate network (we’d argue that we’re much, much better but we’ll leave that to you
              to decide!). That said, we can help you find affiliates.</p>
            <p>Breezy is essentially a super tailored partner search engine. Partners can be found anywhere, so instead
              of
              simply searching one network, Breezy searches the whole web – from Google to YouTube and other search
              engines – to find you the most relevant partners for your brand.</p>
          </Answer>
          <Answer title="What does Breezy offer that affiliate networks don’t?">
            <p>For starters, an affiliate network can only recommend other brands and individuals who are using the
              network. So, suggestions aren’t particularly relevant. Breezy understands that your potential partners
              could
              be anywhere, so it searches the whole web and then ranks results by relevancy.</p>
            <p> On top of that, Breezy isn’t limited to just affiliate partnerships. You can use the tool to uncover all
              sorts of opportunities, from content partnerships to co-branding, sponsorship and charitable partnerships
              to
              name just a few. </p>
            <p>In short, Breezy is all about helping you to uncover the most relevant and suitable partners for your
              brand
              – wherever and whoever they may be.</p>
          </Answer>
          <Answer title="Can I register with Breezy as a publisher or affiliate?">
            <p>Not yet, but you will be able to soon. Watch this space!</p>
          </Answer>
        </ul>
      </li>
      <li>
        <h3 className="pt-20 mb-8">Partner Searches</h3>
        <ul>
          <Answer title="How exactly does a Breezy Partner Search work?">
           <p>
             Every search you run on Breezy is powered by the information you give us. Before our discovery engine gets to work, we’ll ask you to input keywords and competitors related to the Product you’re looking for partners to promote. We’ll then uncover thousands of relevant Leads for you to sort and filter to your liking.
           </p>
            <p>
              The more data you give us, the more powerful your search will be, as it will allow us to find a greater number of Leads that are more highly relevant to display in your results page. Read our guide to <a
              href="https://breezy.io/blog/how-to-run-partner-search-breezy">how to run a Partner Search</a> for more.
            </p>
          </Answer>
          <Answer title="How long does it take to run a Partner Search?">
            <p>That depends on how much data you put in! The more information you give us, the more results we’ll be
              able
              to track down for you (and the longer it’ll take).</p>

            <p className={styles.highlight}>If you give us <Highlight text="15 of your competitors"/> and <Highlight
              text="400 keywords"/> (which would cost you <b>625 Breezy Credits</b>), it shouldn’t take any longer
              than <Highlight text="15-20 minutes"/> to
              load up your report. Just enough time for a cuppa and a quick phone call with your nan!</p>
          </Answer>
          <Answer title="How do I run the best Partner Search possible?">
            <p>Running a successful Partner Search on Breezy all comes down to three things: </p>
            <ol>
              <li><strong>Detail:</strong> the more information you give us about your keywords and competitors, the
                more relevant partner
                suggestions we can generate for your brand.
              </li>
              <li><strong>Specificity:</strong> If your business sells more than one product, try running more than one
                search. By mixing
                your competitors and keywords for separate products, your results are likely to get diluted, making them
                less relevant!
              </li>
              <li><strong>Variants:</strong> Try running searches for different sets of indirect keywords your customers
                might be searching
                for. For example, an estate agent might input keywords related to death, debt or divorce to find
                relevant
                partners outside of the property industry.
              </li>
            </ol>
          </Answer>
        </ul>
      </li>
      <li>
        <h3 className="pt-20 mb-8" id="credits">Breezy Credits</h3>
        <ul>
          <Answer title="What are Breezy Credits?">
            <p>
              Breezy Credits are a form of internal currency that you can use to access specific Breezy services. Each Breezy Credit has a value of £0.50. They can’t be exchanged for cash and can only be used in the specific manner indicated by us.
            </p>
            <p>Credits can be allocated to different actions including:</p>
            <strong>Running a search</strong>
            <p>Every input you enter costs Breezy credits. When you run a search, we’ll ask you to enter two main kinds of inputs.</p>
            <ul className={styles.listItem}>
              <li>Keywords or search phrases: these cost 1 Breezy Credit each.</li>
              <li>URLs of competitors or similar brands: these cost 15 Breezy Credits each.</li>
            </ul>
            <strong>Obtaining Prospect contact information</strong>
            <p>
              If you request Prospect contact information, you’ll be charged 3 Breezy Credits for each contact you receive. You’ll be charged once Prospect contacts have been found rather than on request. This way, if we are unable to find a suitable Prospect contact, you won’t have to spend any Breezy credits.
            </p>
          </Answer>
          <Answer title="Are there any limits on what you can use Breezy Credits for?">
            <p>
              You can use your Breezy Credits in any combination you like. For example, you might choose to spend 200 Breezy Credits on running a single Partner Search. Or, you could use the same number of credits to run multiple searches and obtain Prospect contact information.
            </p><p>
              While running a search, you can also add any combination of keywords and competitors, provided that you have enough credits in your account.
            </p><p>
              Just bear in mind that every search requires you to enter at least 1 keyword or search phrase, and at least 1 competitor or similar brand URL. This means that a search will take up at least 16 credits.
            </p>
          </Answer>
          <Answer title="How do I buy Breezy Credits?">
            <p>Your Breezy subscription will come with a set number of credits per month. You can choose between three tiers: 250 credits, 500 credits and 1,000 credits. You can also top-up as many times as you like to add more credits on top of your existing subscription plan.</p>
          </Answer>
          <Answer title="Do my Breezy Credits expire?">
            <p>
              No. Any unused Breezy credits associated with your trial subscription or with a full subscription will remain in your account even if your subscription has expired or been cancelled. They’ll be usable in the future if you return to purchase a paid subscription with the same Brand Account.
            </p>
          </Answer>
        </ul>
      </li>
    </ul>
  )
}

export default Faq
