import React, { FunctionComponent } from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Pill from "../../components/Pill"
import Container from "../../components/Container"
import { graphql } from "gatsby"
import ActionPanel from "../../components/home/ActionPanel"
import SignUpForm from "../../components/SignUpForm"
import Highlight from "../../components/home/Highlight"
import Grid from "../../components/Grid"
import Faq from "../../components/home/Faq"
import styles from "./index.module.css"

interface QueryResult {
  mdx: {
    frontmatter: {
      title: string
      seo: {
        title: string
        description: string
      }
    }
    body: string
  }
}

interface Props {
  data: QueryResult
}

const HowItWorksPage: FunctionComponent<Props> = ({ data }) => {
  const page = data.mdx
  // @ts-ignore
  return (
    <Layout>
      <Seo
        description={page.frontmatter.seo.description}
        pathname="/comparison/similarweb"
        title={page.frontmatter.seo.title}
      />

      <header>
        <Pill bgClass="bg-green-100" variant="bottom-right">
          <Container style={{maxWidth: '85ch'}}>
            <div className="br-prose py-24">
              <div className="br-col-gap md:grid md:grid-cols-12">
                <div className="md:col-span-12 md:col-start-1 xxl:col-span-9 xxl:col-start-1">
                  <h1 className="mb-8 text-left">{page.frontmatter.title}</h1>
                  <div className="text-left">
                    <p className={styles.subTitle}>How Breezy makes finding relevant partners quicker and <Highlight
                      text="hassle-free."/></p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Pill>
      </header>
      <section className="py-24 lg:py-40 mx-auto" style={{maxWidth: '90ch'}}>
        <section className="mb-20 px-6 md:mb-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-7 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2 className={"font-serif"}>Keyword-based search</h2>
                <p>
                  You tell us what your customers are searching for and we’ll scour the whole wide web for the most
                  relevant partners for your brand.
                </p>
              </section>

              <section className="col-span-12 row-start-1 xl:row-start-0 mb-8 xl:mb-0 xl:col-span-4 xl:col-start-9 items-center text-center">
                <img alt="Keyword-based search" src="./../images/keyword-based.svg"
                     className="inline-block relative"/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="px-6">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-7 xl:col-start-6 xl:-mt-4 xl:pb-0">
                <h2 className={"font-serif"}>Competitor partner analysis</h2>
                <p>
                  We’ll dig out the details of your competitors’ partnerships and uncover lucrative lookalike
                  opportunities. Sshhh, don’t tell!
                </p>
              </section>

              <section className="col-span-12 row-start-1 xl:row-start-0 mb-8 xl:mb-0 xl:col-span-4 xl:col-start-1 xl:row-start-1 items-center text-center">
                <img alt="Competitor partner" src="./../images/competitor-partner.svg"
                     className="inline-block relative"/>
              </section>
            </Grid>
          </Container>
        </section>
        <section className="py-24 lg:py-40">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-7 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2 className={"font-serif"}>Partner relevancy ranking</h2>
                <p>
                  No more sifting through pages of irrelevant listings. We give every partnership prospect a relevancy ranking so you can find the best first.
                </p>
              </section>

              <section className="col-span-12 row-start-1 xl:row-start-0 mb-8 xl:mb-0 xl:col-span-4 xl:col-start-9 items-center text-center">
                <img alt="Partner relevancy" src="./../images/partner-relevancy.svg"
                     className="inline-block relative"/>
              </section>
            </Grid>
          </Container>
        </section>
        <section className="px-6">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-7 xl:col-start-6 xl:-mt-4 xl:pb-0">
                <h2 className={"font-serif"}>Data snapshot</h2>
                <p>
                  Gone are the days of trawling through hundreds of websites. View a partner’s overlap, reach, geography, existing partners and more without ever leaving Breezy.
                </p>
              </section>

              <section className="col-span-12 row-start-1 xl:row-start-0 mb-8 xl:mb-0 xl:col-span-4 xl:col-start-1 xl:row-start-1 items-center text-center">
                <img alt="Data-snapshot" src="./../images/data-snapshot.svg"
                     className="inline-block relative"/>
              </section>
            </Grid>
          </Container>
        </section>
        <section className="py-24 lg:py-40">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-7 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2 className={"font-serif"}>Advanced filters</h2>
                <p>
                  Looking for that hidden partner opportunity? Find them quickly by filtering results by location,
                  category, number of competitors, number of keywords they rank for and more.
                </p>
              </section>

              <section className="col-span-12 row-start-1 xl:row-start-0 mb-8 xl:mb-0 xl:col-span-4 xl:col-start-9 items-center text-center">
                <img alt="Advanced filters" src="./../images/advanced-filters.svg"
                     className="inline-block relative"/>
              </section>
            </Grid>
          </Container>
        </section>
        <section className="px-6">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-7 xl:col-start-6 xl:-mt-4 xl:pb-0">
                <h2 className={"font-serif"}>Advanced organisation</h2>
                <p>
                  Easily arrange and categorise your Leads to suit your internal preferences, using features like notes, favourites and lists.
                </p>
              </section>

              <section className="col-span-12 row-start-1 xl:row-start-0 mb-8 xl:mb-0 xl:col-span-4 xl:col-start-1 xl:row-start-1 items-center text-center">
                <img alt="Advanced organisation" src="./../images/advanced-organisation.svg"
                     className="inline-block relative"/>
              </section>
            </Grid>
          </Container>
        </section>
        <section className="py-24 lg:py-40">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-7 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2 className={"font-serif"}>Get contact information</h2>
                <p>
                  Request contact information for a list of Prospects that you wish to reach out to, and we will
                  discover the information to power your outreach.
                </p>
              </section>

              <section className="col-span-12 row-start-1 xl:row-start-0 mb-8 xl:mb-0 xl:col-span-4 xl:col-start-9 items-center text-center">
                <img alt="Get contact" src="./../images/get-contact.svg"
                     className="inline-block relative"/>
              </section>
            </Grid>
          </Container>
        </section>
      </section>
      <Pill bgClass="bg-green-100" variant="top-right">
        <Container>
          <div className="pt-24 xxl:pt-32 mx-auto" style={{maxWidth: '70ch'}}>
            <h2 className="m-0 font-serif">Frequently asked questions</h2>
            <Faq />
          </div>
        </Container>
      </Pill>
      <Pill bgClass="bg-green-100" variant="bottom-left">
        <Container>
          <div className="py-24 xxl:py-32 text-center">
            <h2>Have more questions?</h2>
            <p className="text-6">Email us at welcome@breezy.io</p>
          </div>
        </Container>
      </Pill>
      <div className="pt-48 pb-56">
        <ActionPanel>
          <h2 className="mb-6 text-4 md:mb-8 md:text-8">
            Join Breezy
          </h2>

          <p className="mb-10 md:mb-16">
            Say goodbye to your realms of spreadsheets and make Breezy the place you organise, filter and sort your
            partner prospects 10x more efficiently. What are you waiting for?
          </p>

          <div className="max-w-xl mx-auto">
            <SignUpForm/>
          </div>
        </ActionPanel>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HowItWorks {
    mdx(fileAbsolutePath: { regex: "//static/how-it-works-index/" }) {
      frontmatter {
        title
        seo {
          description
          title
        }
      }
      body
    }
  }
`

export default HowItWorksPage
